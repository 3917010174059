import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Tutorial } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class KnowledgeHubService {
  knowledgeHubCompanyPath = `/api/tutorials`;
  knowledgeHubAdminPath = `/api/admin-tutorials`;

  constructor(private apiService: ApiService) {}

  public getKnowledgeHubTutorials(displayDeletedAtValues = false) {
    return this.apiService.get(`${this.knowledgeHubCompanyPath}/index${displayDeletedAtValues ? '?with_trashed=true' : ''}`);
  }

  public addKnowledgeHubTutorial(video: Tutorial) {
    return this.apiService.post(`${this.knowledgeHubCompanyPath}/store`, video);
  }

  public updateKnowledgeHubTutorial(video: Tutorial) {
    const dataObject = {
      uuid: video?.uuid,
      btr_tutorial_uuid: video?.btr_tutorial_uuid,
      video_title: video?.video_title,
      video_link: video?.video_link
    }

    if (!video?.btr_tutorial_uuid) {
      delete dataObject.btr_tutorial_uuid;
    }

    if (!video?.uuid ||video?.tutorial_type === 'admin') {
      delete dataObject.uuid;
    }

    return this.apiService.post(`${this.knowledgeHubCompanyPath}/update`, dataObject);
  }

  public resetKnowledgeHubTutorial(btrTutorialUuid: string) {
    return this.apiService.post(`${this.knowledgeHubCompanyPath}/reset`, { btr_tutorial_uuid: btrTutorialUuid });
  }

  public deleteKnowledgeHubTutorial(video: Tutorial) {
    const dataObject = {
      uuid: video?.uuid,
      btr_tutorial_uuid: video?.btr_tutorial_uuid
    }

    if (video.tutorial_type === 'admin') {
      delete dataObject.uuid;
    } else {
      delete dataObject.btr_tutorial_uuid;
    }

    return this.apiService.post(`${this.knowledgeHubCompanyPath}/delete`, dataObject);
  }

  public getKnowledgeHubTutorialsBTRADefaultTutorials() {
    return this.apiService.get(`${this.knowledgeHubAdminPath}`);
  }

  public addKnowledgeHubTutorialBTRADefaultTutorials(video: Tutorial) {
    return this.apiService.post(this.knowledgeHubAdminPath, video);
  }

  public updateKnowledgeHubTutorialBTRADefaultTutorials(video: Tutorial) {
    return this.apiService.patch(`${this.knowledgeHubAdminPath}/${video.btr_tutorial_uuid}`, video);
  }

  public deleteKnowledgeHubTutorialBTRADefaultTutorials(video: Tutorial) {
    return this.apiService.delete(`${this.knowledgeHubAdminPath}/${video.btr_tutorial_uuid}`);
  }

  public getKnowledgeHubTutorialsBTRAdminCompany(company_uuid: string) {
    return this.apiService.get(`${this.knowledgeHubAdminPath}/list/${company_uuid}`);
  }
}
