<div *ngIf="(isDefaultTutorials && !isLoading) || !isDefaultTutorials" class="margin-top-10" [ngClass]="{'default-tutorials': isDefaultTutorials}">
  <div *ngFor="let category of knowledgeHubAdminTables" class="category-table margin-top-20"> 
    <div class="flex-100 justify-between">
      <div class="category-title"> {{category.title}} </div>
      <app-styleguide-simple-button
        *ngIf="!isBTRAdminInCompanySettings"
        type="green-filled"
        label="Add new video"
        icon="icon-n-plus"
        (onClick)="addVideo(categoryKeyMapping[category.title])">
      </app-styleguide-simple-button>
    </div>
    <div class="table flex-col flex-100">
      <table
        class="scrolling-table btr-table"
        mat-table
        [dataSource]="category.items"
        >
        <tr class="big-table-header" mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let video; columns: columnsToDisplay" 
          [ngClass]="{
             'edited-row': video?.tutorial_type === 'inherited',
             'disabled-row': video?.deleted_at
            }">
        </tr>
    
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-results" [colSpan]="columnsToDisplay.length">
            <span>No video links added yet</span>
          </td>
        </tr>
        <ng-container matColumnDef="video_title">
          <th mat-header-cell *matHeaderCellDef>Video Title</th>
          <td mat-cell *matCellDef="let video">{{video.video_title}}</td>
        </ng-container>
    
        <ng-container matColumnDef="video_link">
          <th mat-header-cell *matHeaderCellDef>Video Link</th>
          <td mat-cell *matCellDef="let video">{{video.video_link}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th class="td-actions" mat-header-cell *matHeaderCellDef></th>
          <td class="td-actions" mat-cell *matCellDef="let video">
            <div *ngIf="!isBTRAdminInCompanySettings" class="btn-actions">
              <button mat-icon-button [matMenuTriggerFor]="menu" [disableRipple]="true">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="btr-basic user-menu" xPosition="before">
                <div *ngIf="video?.tutorial_type === 'inherited'">
                  <button mat-menu-item (click)="resetVideo(video)">
                    <span>Reset to default</span>
                  </button>
                </div>
                <div *ngIf="!video.deleted_at || isDefaultTutorials">
                  <button mat-menu-item (click)="renameVideo(video)">
                    <span>Rename</span>
                  </button>
                </div>
                <div *ngIf="!video.deleted_at || isDefaultTutorials">
                  <button mat-menu-item (click)="editVideoLink(video)">
                    <span>Edit link</span>
                  </button>
                </div>
                <div *ngIf="!video.deleted_at || isDefaultTutorials">
                  <button mat-menu-item (click)="deleteVideo(video)">
                    <span class="ico-n-unlocked unlocked-icon"></span>
                    <span>Delete</span>
                  </button>
                </div>
              </mat-menu>
            </div>
          </td>
        </ng-container>
      </table>
    </div>
  </div>
</div>

<app-loading-animation *ngIf="isLoading && isDefaultTutorials" [withLogo]="false" [size]="40"></app-loading-animation>