<div class="menu-toggle-fixed-container"
     *ngIf="router.url !=='/login' && router.url !=='/forgot-password' && router.url !=='/page-not-found'">
  <div class="menu-toggle-wrapper" [ngClass]="isMenuOpened ? 'menu-opened' : ''">
    <div class="expand-toggle-container flex-row justify-center items-center" (click)="toggleMenu()">
      <span class="icon-n-left left-arrow" *ngIf="isMenuOpened"></span>
      <span class="icon-n-right right-arrow" *ngIf="!isMenuOpened"></span>
    </div>
  </div>
</div>

<mat-sidenav-container class="sidenav-container"
                       *ngIf="router.url !=='/login' && router.url !=='/forgot-password' && router.url !=='/page-not-found'">
  <mat-sidenav #drawerDesktop
                [disableClose]="true"
                class="sidenav"
                [ngClass]="isMenuOpened ? 'menu-opened' : ''"
                fixedInViewport
                role="navigation"
                mode="side"
                opened="true"
                position="start">
    <ng-container *ngTemplateOutlet="menuContent"></ng-container>
  </mat-sidenav>

  <ng-template #menuContent>
    <mat-card-title>
    </mat-card-title>
    <ng-container *ngTemplateOutlet="desktopContent"></ng-container>
  </ng-template>

  <ng-template #desktopContent>
    <div class="desktop-menu-container flex-col justify-between">
      <mat-nav-list class="nav-list-desktop flex-col flex-100 items-stretch">
        <a mat-ripple class="menu-company" *appUserAccessLevel="[ AccessLevel.User ]">
          <div class="section-btn menu-company-logo">
            <span class="menu-icon-wrapper tst-user-icon-container">
              <img *ngIf="company && company.image_url" [style.background-image]="'url(' + company.image_url + ')'" class="menu-company-logo-image" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/>
              <img *ngIf="!company || !company.image_url" src="../../../../assets/img/favicon.ico" class="menu-company-logo-image"/>
            </span>
            <span class="show-on-hover">
              {{ company?.name ? company.name : 'Res_Q Admin'}}
            </span>
          </div>
        </a>

        <!-- Navbar -->
        <ng-container *ngFor="let item of navMenu">
          <ng-container *ngIf="item.isVisible">
            <a *appUserAccessLevel="item.accessLevels"
               [routerLinkActive]="item.routerLinkActive || ''"
               [ngClass]="item.className || ''"
               [routerLink]="item.url"
               [queryParams]="item.queryParams"
               mat-ripple>
              <div class="section-btn">
                <span class="menu-icon-wrapper">
                  <span [class]="item.iconClass"></span>
                </span>
                <span class="show-on-hover">{{item.text}}</span>
              </div>
            </a>
          </ng-container>
        </ng-container>
        <ng-container>
          <ng-container *ngIf="!(isBTRAdmin$ | async)">
            <a (click)="openKnowledgeHubModal()">
              <div 
              class="section-btn"
              matTooltip="Res_Q Tutorials"
              matTooltipClass="btr-basic small"
              matTooltipPosition="below">
                <span class="menu-icon-wrapper">
                  <span class="icon-n-school1"></span>
                </span>
                <span class="show-on-hover">Res_Q Tutorials</span>
              </div>
            </a>
          </ng-container>
          <ng-container *ngIf="(isBTRAdmin$ | async)">
            <a *appUserAccessLevel="[AccessLevel.BTRAdmin]"
            routerLinkActive="is-active"
            routerLink="tutorials-admin"
            mat-ripple>
              <div 
                class="section-btn"
                matTooltip="Res_Q Tutorials"
                matTooltipClass="btr-basic small"
                matTooltipPosition="below">
                <span class="menu-icon-wrapper">
                  <span class="icon-n-school1"></span>
                </span>
                <span class="show-on-hover">Res_Q Tutorials</span>
              </div>
            </a>
          </ng-container>
        </ng-container>
      </mat-nav-list>

      <mat-nav-list class="nav-list-desktop bottom-menu">
        <a id="intercom-trigger-btn" (click)="toggleHubSpot()">
          <div class="section-btn">
            <span class="icon-n-help menu-icon-wrapper intercom-trigger"></span>
            <span class="show-on-hover">HubSpot</span>
          </div>
        </a>
        <a mat-ripple [matMenuTriggerFor]="bottomTaskMenu" (click)="resetCounter(); hideHubSpot()">
          <div class="section-btn">
            <span class="menu-icon-wrapper">
              <span class="bottom-menu-item notifications-bell" mat-button>
                <ng-container *ngIf="totalUnseen$ | async as unseen">
                  <span *ngIf="unseen > 0"
                        class="bell-notification-count"
                        [innerHTML]="unseen">
                  </span>
                </ng-container>
                <span class="icon-n-notifications"></span>
              </span>
            </span>
            <span class="show-on-hover">Notifications</span>
          </div>
        </a>
        <a mat-ripple [matMenuTriggerFor]="profileMenu">
          <div class="section-btn">
            <span class="menu-icon-wrapper">
              <span class="bottom-menu-item" mat-button>
                <img class="user-photo" mat-card-xs-image *ngIf="currentUser && currentUser.image_url"
                      src="{{currentUser.image_url}}"/>
                <img class="user-photo" mat-card-xs-image *ngIf="!currentUser || !currentUser.image_url"
                      src="../../../../assets/img/profile-default.png"/>
              </span>
            </span>
            <span class="show-on-hover user-profile-name"> {{ currentUser ? (currentUser | displayName) : 'My Profile'}} </span>
          </div>
        </a>
        <mat-menu #profileMenu="matMenu" yPosition="above" xPosition="after" class="btr-basic">
          <button mat-menu-item (click)="goToProfile()">My Profile</button>
          <button (click)="onLogout()" mat-menu-item class="tst-logout-btn">Logout</button>
        </mat-menu>
        <mat-menu #bottomTaskMenu="matMenu" class="btr-basic notifications-menu-panel">
          <ng-container *ngIf="notifications$ | async">
            <app-notifications-menu [notifications$]="notifications$"></app-notifications-menu>
          </ng-container>
        </mat-menu>
      </mat-nav-list>
    </div>
  </ng-template>
</mat-sidenav-container>
