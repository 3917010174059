import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse, NotificationTemplate } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-market-package-removed-or-changed',
  templateUrl: './market-package-removed-or-changed.component.html',
  styleUrls: ['./market-package-removed-or-changed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MarketPackageRemovedOrChangedComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
  readonly NotificationTemplate = NotificationTemplate;

  openNotification(notification: NotificationResponse, isLocal = false): void {
    switch (notification.template) {
      case NotificationTemplate.PackageRemovedToParentCompany:
      case NotificationTemplate.PackageChangedByCompany:
        this.goToPackage(notification, isLocal);
        break;
      case NotificationTemplate.PackageRemovedByCompany:
        this.goToPrivateMarketplace(notification);
        break;
      case NotificationTemplate.PackageRemovedDueToGlobalSystemDelete:
        this.goToMyPackagesUploads(notification);
        break;
    }
  }

}
