import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { CategoryKeyMapping, MenuCategory, Subcategory, Tutorial } from '../../../core/interfaces';
import { HelperService, KnowledgeHubService } from '../../../core/services';
import { take } from 'rxjs';
import { KNOWLEDGE_HUB_MAIN_MENU } from '../../../core/constants/app-constants';

@Component({
  selector: 'app-knowledge-hub-modal',
  templateUrl: './knowledge-hub-modal.component.html',
  styleUrls: ['./knowledge-hub-modal.component.scss'],
})
@AutoUnsubscribe()
export class KnowledgeHubModalComponent implements OnInit {
  menuCategories: MenuCategory[] = KNOWLEDGE_HUB_MAIN_MENU;
  menuCategoriesOriginal: MenuCategory[] = KNOWLEDGE_HUB_MAIN_MENU;
  category = '';
  search = '';
  isLoading = true;
  videoUrl = '';
  selectedThumbnail: { parentTitle: string; index: number | null } | null = null;

  constructor(
    private dialogRef: MatDialogRef<KnowledgeHubModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private cdr: ChangeDetectorRef,
    private knowledgeHubService: KnowledgeHubService,
    private helperService: HelperService
  ) {
    this.category = data?.category;
  }

  ngOnInit(): void {
    this.getKnowledgeHubTutorials();
  }

  filterValueChanged(value: string): void {
    this.menuCategories = JSON.parse(JSON.stringify(this.menuCategoriesOriginal));
    this.search = value.trim();
    if (this.search) {
      this.expandMenuCategoryByVideoTitle(this.search);
    } else {
      this.expandMenuCategoryDefault();
    }
    this.cdr.detectChanges();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  selectThumbnail(index: number, parentTitle: string, videoUrl: string): void {
    this.selectedThumbnail = { parentTitle, index };
    this.videoUrl = videoUrl;
  }

  isThumbnailSelected(index: number, parentTitle: string): boolean {
    return this.selectedThumbnail?.parentTitle === parentTitle && this.selectedThumbnail?.index === index;
  }


  private expandMenuCategoryDefault(): void {
    // Collapse all categories and subcategories first
    this.menuCategories.forEach(category => {
      category.expanded = false;
      category.subcategories.forEach(subcategory => subcategory.expanded = false);
    });

    // Handle both main category and subcategory defaults
    const defaultCategory = this.menuCategories.find(category => category.title === this.category);
    if (defaultCategory) {
      defaultCategory.expanded = true;
      defaultCategory.subcategories.forEach(subcategory => subcategory.expanded = true);
      this.selectThumbnail(0, this.category, defaultCategory.items[0]?.video_link)
    } else {
      // Check if the input is a subcategory title
      for (const category of this.menuCategories) {
        const defaultSubcategory = category.subcategories.find(subcategory => subcategory.title === this.category);
        if (defaultSubcategory) {
          category.expanded = true;
          defaultSubcategory.expanded = true;
          this.selectThumbnail(0, this.category, defaultSubcategory.items[0]?.video_link)
          break;
        }
      }
    }
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  private expandMenuCategoryByVideoTitle(searchValue: string): void {
    // Collapse all categories and subcategories and filter items
    this.menuCategories.forEach(category => {
      category.expanded = false;
      category.items = category.items.filter(item => 
        item.video_title.toLowerCase().includes(searchValue.toLowerCase()));
      
      category.subcategories = category.subcategories.map(subcategory => {
        subcategory.items = subcategory.items.filter(item => 
          item.video_title.toLowerCase().includes(searchValue.toLowerCase()));
        subcategory.expanded = subcategory.items.length > 0; // Expand all matching subcategories
        return subcategory.items.length > 0 ? subcategory : null;
      }).filter(subcategory => subcategory !== null);
    });

    // Remove categories with no matching items or subcategories
    this.menuCategories = this.menuCategories.map(category => {
      category.subcategories = category.subcategories.filter(subcategory => subcategory.items.length > 0);
      return (category.items.length > 0 || category.subcategories.length > 0) ? category : null;
    }).filter(category => category !== null);

    let isAnyCategoryExpanded = false;

    this.menuCategories.forEach(category => {
      const categoryMatches = category.items.length > 0;
      
      const subcategoryMatches = category.subcategories.some(subcategory => {
        if (subcategory.expanded) isAnyCategoryExpanded = true; // Ensure all matching subcategories are expanded
        return subcategory.expanded;
      });

      if (categoryMatches || subcategoryMatches) {
        category.expanded = true;
        isAnyCategoryExpanded = true;
      }
    });

    // Ensure at least one category remains expanded if search results are empty
    if (!isAnyCategoryExpanded) {
      this.expandMenuCategoryDefault();
    }

    this.cdr.detectChanges();
  }

  private getKnowledgeHubTutorials(): void {
   // Clear existing items before adding new ones
    this.menuCategories.forEach((menuCategory: MenuCategory) => {
      menuCategory.items = [];
      menuCategory.subcategories.forEach((subCategory: Subcategory) => {
        subCategory.items = [];
      })
    });

    this.knowledgeHubService.getKnowledgeHubTutorials(false)
      .pipe(take(1)).subscribe(res => {
        res.forEach((tutorial: Tutorial) => {
          this.menuCategories.forEach((menuCategory: MenuCategory) => {
            if (tutorial.category_identifier === CategoryKeyMapping[menuCategory.title]) {
              menuCategory.items = [...menuCategory.items, tutorial];
            } else {
              menuCategory.subcategories.forEach((subCategory: Subcategory) => {
                if (tutorial.category_identifier === CategoryKeyMapping[subCategory.title]) {
                  subCategory.items = [...subCategory.items, tutorial];
                }
              })
            }
          });
        });
        Object.assign(this.menuCategoriesOriginal, this.menuCategories);
        this.expandMenuCategoryDefault();
        this.cdr.markForCheck();
      },
      (err) => {
        this.isLoading = false;
        this.helperService.showErrorMessage(err);
        this.cdr.markForCheck();
      });
  }
}
