import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ConfigStateTutorialsModel } from '../../store/config/config.state';

export interface GetConfigResponse {
  copyright: string; // webviewerKey
  copyright2: string; // syncfusionKey
  screen_recording_height: number;
  screen_recording_width: number;
  screen_recording_bitrate: number;
  tutorials: ConfigStateTutorialsModel;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  readonly configPath = '/api/config';

  constructor(private apiService: ApiService) { }

  getConfig(): Observable<GetConfigResponse> {
    return this.apiService.get(`${this.configPath}/`);
  }
}
