export interface MenuCategory {
  title: string;
  subcategories: Subcategory[];
  expanded: boolean;
  items: Tutorial[];
}

export interface Subcategory {
  title: string;
  items: Tutorial[];
  expanded: boolean;
}

export interface Tutorial {
  uuid?: string;
  btr_tutorial_uuid?: string;
  video_title: string;
  video_link: string;
  category_identifier?: string;
  tutorial_type?: string;
  deleted_at?: string;
}

export interface MenuCategory {
  title: string;
  subcategories: Subcategory[];
  expanded: boolean;
  items: Tutorial[];
}

export interface MenuCategoryAdminTables {
  title: string;
  items: Tutorial[];
}

// Enum for Main Menu Categories
export enum MenuCategoryEnum {
  Templates = 'Templates',
  Workspace = 'Workspace',
  Reports = 'Reports',
  MyTasks = 'My Tasks',
  CompanySettings = 'Company Settings',
  AuditTrail = 'Audit Trail',
  MyProfile = 'My Profile'
}

// Enum for Subcategories
export enum SubcategoryEnum {
  TemplatesPage = 'Templates Page',
  TemplatesConfiguration = 'Templates Configuration',
  TemplatesImpactAssessment = 'Templates Impact Assessment',
  WorkspacePage = 'Workspace Page',
  DocumentsAndWorkflows = 'Documents & Workflows',
  ImpactAssessment = 'Impact Assessment'
}

export enum CategoryKeyMapping {
  'Templates Page' = 'templates_page',
  'Templates Configuration' = 'templates_configuration',
  'Templates Impact Assessment' = 'templates_impact_assessment',
  'Workspace Page' = 'workspace_page',
  'Documents & Workflows' = 'workspace_documents_workflows',
  'Impact Assessment' = 'workspace_impact_assessment',
  'Reports' = 'reports',
  'My Tasks' = 'my_tasks',
  'Company Settings' = 'company_admin',
  'Audit Trail' = 'audit_trail',
  'My Profile' = 'my_profile'
}

export enum KnowledgeHubTutorialModalModes {
  Add = 'add',
  Edit = 'edit',
  Rename = 'rename'
}
