import { Component, Input } from '@angular/core';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';
import { NotificationResponse } from '@core/interfaces';

@Component({
  selector: 'app-execution-request-canceled-by-only-one-user',
  templateUrl: './execution-request-canceled-by-only-one-user.component.html',
  styleUrls: ['./execution-request-canceled-by-only-one-user.component.scss']
})
export class ExecutionRequestCanceledByOnlyOneUserComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
}
