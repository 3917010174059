import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { GetConfig } from "./config.actions";
import { ConfigService, GetConfigResponse } from "../../core/services/config.service";
import { map, tap } from "rxjs";

export interface ConfigStateModel {
  webviewerKey: string;
  syncfusionKey: string;
  videoRecordingSettings: ConfigStateVideoSettingsModel;
  tutorials: ConfigStateTutorialsModel;
}

export interface ConfigStateTutorialsModel {
  templates_page: number;
  templates_configuration: number;
  templates_impact_assessment: number;
  workspace_page: number;
  workspace_documents_workflows: number;
  workspace_impact_assessment: number;
  reports: number;
  my_tasks: number;
  company_admin: number;
  audit_trail: number;
  my_profile: number;
}

export interface ConfigStateVideoSettingsModel {
  width: number;
  height: number;
  videoBitsPerSecond: number;
}

@State<ConfigStateModel>({
  name: 'ConfigState'
})

@Injectable()
export class ConfigState {
  constructor(private configService: ConfigService) { }

  @Action(GetConfig, { cancelUncompleted: true })
  getConfig({ patchState }: StateContext<ConfigStateModel>) {
    return this.configService.getConfig().pipe(
      tap((config: GetConfigResponse) => {
        patchState({
          webviewerKey: config.copyright,
          syncfusionKey: config.copyright2,
          videoRecordingSettings: {
            width: config.screen_recording_width,
            height: config.screen_recording_height,
            videoBitsPerSecond: config.screen_recording_bitrate
          },
          tutorials: config.tutorials
        })
      })
    );
  }
}
