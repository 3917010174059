import { IUserLite } from "@core/models";

export interface IReportListingConfiguration {
  key: string;
  title: string;
  columns: string[];
  columnsMapping: IReportListingColumnsMapping[];
  sortDefault: string;
  sortDirection?: string;
  paginationKey: string;
  filterKey: string;
  savedViewsKey: string;
  searchFilterKey: string;
  hasFilters?: boolean;
}

export interface IReportListingColumnsMapping {
  key: string;
  title: string;
  rowDataKey: string;
  isLink?: boolean;
  tooltip?: string;
  isNotSortable?: boolean;
  isGMTDate?: boolean;
}

export enum ReportsURLSectionTitle {
  systems = 'Systems',
  workflows = 'Workflows',
  'workflow-deliverables' = 'Workflow Deliverables',
  'test-scripts' = 'Test Scripts Executions',
  deviations = 'Deviations',
  tasks = 'Tasks',
  reviews = 'Reviews',
  'periodic-reviews' = 'Periodic Reviews',
  'project-management' = 'Project Management',
}

export enum ReportsURLSectionTitleAdmin {
  licenses = 'Licenses',
  usages = 'Data Usage',
  users = 'Users',
}

export enum ReportsTitleURLKey {
  Reports = 'reports',
  Dashboard = 'dashboard',
  Systems = 'systems',
  Workflows = 'workflows',
  WorkflowDeliverables = 'workflow-deliverables',
  TestScripts = 'test-scripts',
  Deviations = 'deviations',
  Tasks = 'tasks',
  Reviews = 'reviews',
  PeriodicReviews = 'periodic-reviews',
  ProjectManagement = 'project-management',
}

export enum ReportsTitleURLKeyAdmin {
  Licenses = 'licenses',
  Usages = 'usages',
  Users = 'users',
}

export enum ReportType {
  systems = 'systems_report',
  workflows = 'workflows_report',
  'workflow-deliverables' = 'deliverables_report',
  'test-scripts' = 'ts_execution_report',
  deviations = 'deviations_report',
  'tasks' = 'tasks_report',
  'project-management' = 'project_management_report',
  reviews = 'reviews_report',
  'periodic-reviews' = 'periodic_reviews_report',
  'requirements' = 'requirements_report',
  'assignments-by-user' = 'assignments_by_user_report',
  'tasks-by-user' = 'tasks_by_user_report',
}

export enum ReportTypeAdmin {
  licenses = 'licenses_report',
  usages = 'usages_report',
  users = 'users_report',
}

export enum ReportTitle {
  Systems = 'Systems Report',
  Workflows = 'Workflows Report',
  WorkflowDeliverables = 'Workflow Deliverables Report',
  TestScripts = 'Test Scripts Executions Report',
  Deviations = 'Deviations Report',
  Tasks = 'Tasks Report',
  ProjectManagement = 'Project Management Report',
  Reviews = 'Reviews Report',
  PeriodicReviews = 'Periodic Reviews Report',
  LinkedRequirements = 'Linked Requirements Report',
}

export enum ReportTitleAdmin {
  Licenses = 'Licenses Report',
  Usages = 'Data Usage Report',
  Users = 'Users Report'
}

export enum ReportsListingActionType {
  CSV = 'csv',
  XLS = 'xls',
  PDF = 'pdf',
}

export enum ReportsQualityDashboardCardCategories {
  ApprovedWorkflows = 'approved_workflows',
  ExecutionsWithDeviations = 'executions_with_deviations',
  SystemsPeriodicReview = 'periodic_reviews',
  Systems = 'systems',
  ActiveWorkflows = 'latest_active_workflows',
};

export enum ReportsValidationProjectDashboardCardCategories {
  LinkedRequirements = 'linked_requirements',
  ActiveExecutions = 'active_executions',
  AverageTimePerExecution = 'average_time_per_execution',
  OverdueTasks = 'overdue_tasks',
  Documents = 'documents',
};

export enum ReportsProjectManagementDashboardCardCategories {
  Assignments = 'assignments',
  CurrentTasks = 'current_tasks',
  AverageTimePerTask = 'avg_time_per_task',
  MostAssignments = 'most_assignments',
  MostFinishedTasksWithDelays = 'most_finished_tasks_with_delays',
  MostFinishedTasks = 'most_finished_tasks',
};

export enum ReportsProjectComparisonDashboardCardCategories {
  TotalLinkedDocuments = 'total_linked_documents',
  TestScriptExecutions = 'test_script_executions',
  AverageTimePerExecution = 'average_time_per_execution_comparison',
  AverageTimePerTask = 'avg_time_per_task_comparison',
  Workflows = 'workflows',
};

export enum ReportsDashboardViewCategories {
  Quality = 'Quality Dashboard',
  ValidationProject = 'Validation Project Dashboard',
  ProjectManagement = 'Project Management Dashboard',
  ProjectComparison = 'Project Comparison Dashboard'
};

export enum ReportsDashboardDocumentTypes {
  general = 'General',
  requirements = 'Requirements',
  ts = 'Test Scripts',
  tse = 'Executed Test Scripts',
  tm = 'Trace Matrix'
}

export enum ReportsDashboardDocumentTypesProjectManagementFilters {
  General = 'general_document',
  Requirements = 'requirement_document',
  'Test Scripts' = 'test_script',
  'Executed Test Scripts' = 'test_script_execution',
  'Trace Matrix' = 'trace_matrix'
}

export interface ReportPrintingData {
  report_title: string;
  report_data_last_updated: string;
  permission?: boolean;
  is_report_admin?: boolean;
}

export interface ReportsDashboardsActiveWorkflows {
  uuid: string;
  display_title: string;
  display_label: string;
  last_updated: string;
  change_descriptions_no: number;
  risk_assessments: string;
  risk_impact: string;
  system_name: string;
  workflow_priority: string;
}

export interface ReportsDashboardsDocuments {
  type: string;
  draft: string;
  in_review: string;
  waiting_for_signature: number;
  in_execution: string;
  cancelled: string;
  approved: string;
}

export interface ReportsDashboardsPeriodicReviewSystems {
  uuid: string;
  name: string;
  periodic_review_date: boolean
}

export interface ReportsDashboardsMostAssignments {
  uuid: string;
  user: IUserLite;
  tasks: number;
}

export interface ReportsDashboardsMostFinishedTasks {
  uuid: string;
  user: IUserLite;
  tasks: number;
}

export interface ReportsDashboardsSystems {
  uuid: string;
  name: string;
  risk: string;
  releases_no: number;
}

export interface ReportsDashboardsTotalLinkedDocuments {
  label: string;
  system1: number;
  system2: number;
  percentage_difference: number;
}

export interface ReportsDashboardsWorkflows {
  compare: string;
  number_of_workflows: number | string;
  number_of_executions: number | string;
  number_of_requirements: number | string;
  number_of_deviations: number | string;
  number_of_testers: number | string;
}

export enum KPITypes {
  // Systems Reports KPIs:
  SystemsTemplatesDocumentsWorkflowsCreated = 'entities_count',

  // Workflows Reports KPIs:
  WorkflowsAvgDuration = 'workflows_avg_duration',
  WorkflowsCreated = 'workflows_created',
  WorkflowsApproved = 'workflows_approved',
  WorkflowsRejected = 'workflows_rejected',
  WorkflowsCanceled = 'workflows_canceled',
  WorkflowStatus = 'workflow_status',
  WorkflowPhase = 'workflow_phase',

  // Workflow Deliverables Reports KPIs:
  DeliverablesReport = 'documents_linked',

  // Test Scripts Executions Reports KPIs:
  NumberOfExecutionsWithDeviations = 'ts_executions_with_deviations',
  NumberOfActiveExecutions = 'ts_executions_active',
  ExecutionAverageDuration = 'ts_executions_avg_duration',
  AverageNoFailsPerExecution = 'ts_executions_avg_fails',
  NumberAndTypeOfFails = 'ts_executions_fails',
  AverageDistributionPerExecution = 'ts_executions_avg_distribution',
  AverageNoStepsPerExecution = 'ts_executions_avg_steps',

  // Project Management Reports KPIs:
  DocumentsCreated = 'documents_created',
  DocumentsAssigned = 'documents_assigned',
  DocumentsUnassigned = 'documents_unassigned',
  RequirementsReport = 'requirements_report',
  AssignmentsByUser = 'assignments_by_user_report',
  
  // Tasks:
  TasksByUser = 'tasks_by_user_report',
  TasksAvgTime = 'tasks_avg_time',
  TasksAvgDelay = 'tasks_avg_delay', 

  // Reviews
  ReviewsAvgCycles = 'reviews_avg_cycles', 
  ReviewsAvgComments = 'reviews_avg_comments', 
  ReviewsCycles = 'reviews_cycles', 
  ReviewsUnfinishedCycles = 'reviews_unfinished_cycles', 
  ReviewsFinishedCycles = 'reviews_finished_cycles', 

  // Deviations:
  ExecutionDeviations = 'ts_executions_deviations', 
  AverageDeviationsPerExecution = 'ts_executions_avg_deviations',
}

export enum DateFilter {
  ThirtyDays = 'thirty_days',
  SixMonths = 'six_months',
  TwelveMonths = 'twelve_months',
  Calendar = 'calendar'
}

export enum ReportsResQAdminSettings {
  Quality = 'quality',
  Validation = 'validation',
  ProjectManagement = 'project_management',
  ProjectComparison = 'project_comparison',
  AllKPIs = 'all_kpis'
}
