import { Injectable } from '@angular/core';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, State, StateContext } from '@ngxs/store';
import { ToggleMenu } from './app.actions';

const FULL_PAGE_ROUTES = ['/templates', '/documents', '/home', '/workflows/', '/reports', '/marketplace', '/global-systems', '/company-admin', '/profile', '/companies', '/admin', '/integrations', '/audit-trail', '/request-access', '/my-tasks', 'tutorials-admin'];

// TODO - these can be refactored into a LayoutState
export interface AppStateModel {
  isMenuOpen: boolean;
  loading: boolean;
  isFullScreen: boolean;
  contentFlexSize: string;
  isLoginPage: boolean;
  currentRoute: string;
}

const defaults = {
  isMenuOpen: false,
  loading: false,
  isFullScreen: false,
  contentFlexSize: '100',
  isLoginPage: true,
  currentRoute: null,
};

@State<AppStateModel>({
  name: 'AppState',
  defaults
})
@Injectable()
export class AppState {

  @Action(ToggleMenu, { cancelUncompleted: true })
  toggleMenu({ getState, patchState }: StateContext<AppStateModel>) {
    patchState({ isMenuOpen: !getState().isMenuOpen });
  }

  @Action(RouterNavigation)
  onRouterNavigation({ patchState }: StateContext<AppStateModel>, action: RouterNavigation) {
    patchState({
      isFullScreen: FULL_PAGE_ROUTES.some(substring => action.routerState.url.includes(substring)),
      isLoginPage: action.routerState.url.includes('/login'),
      currentRoute: action.routerState.url
    });
  }
}
