import { Component } from '@angular/core';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';

@Component({
  selector: 'app-knowledge-hub-tutorials-page',
  templateUrl: './knowledge-hub-tutorials-page.component.html',
  styleUrls: ['./knowledge-hub-tutorials-page.component.scss'],
})
@AutoUnsubscribe()
export class KnowledgeHubTutorialsPageComponent{
  constructor() { }
}
