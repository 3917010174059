import { Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-review-request-canceled-by-only-one-user',
  templateUrl: './review-request-canceled-by-only-one-user.component.html',
  styleUrls: ['./review-request-canceled-by-only-one-user.component.scss']
})
export class ReviewRequestCanceledByOnlyOneUserComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
}
