import {
  ChangeDetectionStrategy, Component,
  Input
} from '@angular/core';
import { Router } from '@angular/router';
import { FILTERS_LOCAL_STORAGE_KEY } from '@core/constants/app-constants';
import { ITaskFilter, MyTaskInterface } from '@core/interfaces';
import {
  HelperService, RoutingService
} from '@core/services';

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskCardComponent {
  @Input() tasks: ITaskFilter;

  constructor(
    private router: Router,
    private routingService: RoutingService,
    private helperService: HelperService,
  ) { }

  goToWorkflow(task: MyTaskInterface): void {
    this.router.navigate([this.helperService.getWorkflowUrlFromTask(task)]);
  }

  goToTasks(taskType: string): void {
    const filtersArray = [
      {
        key: "filters.target_date_status",
        value: taskType === 'overdue' ? 'overdue' : 'upcoming'
      },
    ];
    localStorage.removeItem(FILTERS_LOCAL_STORAGE_KEY.MY_TASKS);
    localStorage.setItem(FILTERS_LOCAL_STORAGE_KEY.MY_TASKS, JSON.stringify(filtersArray));
    this.router.navigate([this.routingService.MY_TASKS.url()]);
  }
}
