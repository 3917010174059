<div class="search-form">
  <mat-form-field appearance="outline"
                  floatLabel="auto"
                  class="flex-row"
                  [style.width]="width">
    <input
      type="text"
      [formControl]="formControl"
      [value]="value"
      [placeholder]="placeholder"
      matInput>
    <span matPrefix>
      <mat-icon>search</mat-icon>
    </span>
    <mat-icon matSuffix (click)="clearSearch()" *ngIf="value">close</mat-icon>
  </mat-form-field>
</div>