import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomValidationService } from '@core/validators/customvalidation';
import { debounceTime, filter } from 'rxjs';
import { KnowledgeHubTutorialModalModes, Tutorial } from '../../../../core/interfaces';
import { INPUT_LONG_TITLE_LENGTH } from '../../../../core/constants';

@Component({
  selector: 'app-add-edit-rename-video',
  templateUrl: './add-edit-rename-video.component.html',
  styleUrls: ['./add-edit-rename-video.component.scss'],
})
export class AddEditRenameVideoComponent implements OnInit {
  itemForm: UntypedFormGroup;
  confirmButtonLabel = 'Add';
  modalTitle = '';
  isLoading = false;
  videoTitle = '';
  videoLink = '';
  mode = KnowledgeHubTutorialModalModes.Add;

  readonly INPUT_LONG_TITLE_LENGTH = INPUT_LONG_TITLE_LENGTH;
  readonly knowledgeHubTutorialModalModes = KnowledgeHubTutorialModalModes;

  constructor(
    private dialogRef: MatDialogRef<AddEditRenameVideoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { item?: Tutorial, category?: string, mode?: KnowledgeHubTutorialModalModes },
    private fb: UntypedFormBuilder,
    private customValidation: CustomValidationService
  ) {
  }

  ngOnInit() {
    this.mode = this.data.mode;

    switch (this.mode) {
      case KnowledgeHubTutorialModalModes.Add:
        this.modalTitle = 'Add new video';
        this.confirmButtonLabel = 'Add';

        this.itemForm = this.fb.group({
          video_title: ['', [Validators.required, this.customValidation.noWhitespace()]],
          video_link: ['', [Validators.required, this.customValidation.noWhitespace(), this.customValidation.checkVimeoVideo()]]
        });
        this.subscribeToVideoLinkChanges();
        break;
      case KnowledgeHubTutorialModalModes.Edit:
        this.modalTitle = 'Edit video link';
        this.confirmButtonLabel = 'Edit';
        this.itemForm = this.fb.group({
          video_link: [this.data.item?.video_link, [Validators.required, this.customValidation.noWhitespace(), this.customValidation.checkVimeoVideo()]]
        });
        this.videoTitle = this.data.item?.video_title;
        break;
      case KnowledgeHubTutorialModalModes.Rename:
        this.modalTitle = 'Rename video';
        this.confirmButtonLabel = 'Rename';
        this.itemForm = this.fb.group({
          video_title: [this.data.item?.video_title, [Validators.required, this.customValidation.noWhitespace()]]
        });
        break;
    }
  }

  subscribeToVideoLinkChanges(): void {
    this.itemForm.get('video_link')?.valueChanges
     .pipe(
        debounceTime(300),
        filter(value => this.itemForm?.controls['video_link'].valid)
     )
     .subscribe((value) => {
      this.videoLink = value;
     });
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (!this.itemForm.valid) {
      this.itemForm.markAllAsTouched();
      return;
    }

    switch (this.mode) {
      case KnowledgeHubTutorialModalModes.Add:
        this.dialogRef.close({
          video_title: this.itemForm.get('video_title')?.value,
          video_link: this.itemForm.get('video_link')?.value,
          category_identifier: this.data?.category
        });
        break;
      case KnowledgeHubTutorialModalModes.Edit:
        this.dialogRef.close({
          ...this.data.item,
          video_link: this.itemForm.get('video_link')?.value,
        });
        break;
      case KnowledgeHubTutorialModalModes.Rename:
        this.dialogRef.close({
          ...this.data.item,
          video_title: this.itemForm.get('video_title')?.value,
        });
        break;
    }
  }
}
