import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { KnowledgeHubTutorialVideosComponent } from './knowledge-hub-tutorial-videos.component';
import { AddEditRenameVideoComponent } from './add-edit-rename-video/add-edit-rename-video.component';
import { ComponentsModule } from '../../../shared/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule,
    ComponentsModule
  ],
  exports: [
    KnowledgeHubTutorialVideosComponent
  ],
  declarations: [
    KnowledgeHubTutorialVideosComponent,
    AddEditRenameVideoComponent
  ],
  providers: []
})
export class KnowledgeHubTutorialVideosModule {
}
