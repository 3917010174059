import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@core/modules/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { ComponentsModule } from '@shared/components/components.module';
import { CoreModule } from '@core/core.module';
import { KnowledgeHubTutorialsPageComponent } from './knowledge-hub-tutorials-page.component';
import { KnowledgeHubTutorialVideosModule } from '../../shared/knowledge-hub-tutorial-videos/knowledge-hub-tutorial-videos.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    CoreModule,
    ButtonsModule,
    KnowledgeHubTutorialVideosModule
  ],
  exports: [
    KnowledgeHubTutorialsPageComponent
  ],
  declarations: [
    KnowledgeHubTutorialsPageComponent
  ],
  providers: []
})
export class KnowledgeHubTutorialsPageModule {
}
