import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { CustomStepperModule } from '@shared/components/custom-stepper/custom-stepper.module';
import { FileUploadModule } from '@shared/components/file-upload/file-upload.module';
import { FiltersDropdownModule } from '@shared/components/filters-dropdown/filters-dropdown.module';
import { ImpactAssessmentModule } from '@shared/components/impact-assessment/impact-assessment.module';
import { LoadingAnimationModule } from '@shared/components/loading-animation/loading-animation.module';
import {
  TraceMatrixGeneratedNotificationComponent
} from '@shared/components/notifications-menu/templates/trace-matrix-generated/trace-matrix-generated.component';
import { PageSelectorModule } from '@shared/components/page-selector/page-selector.module';
import { PreviewModeLabelComponent } from '@shared/components/preview-mode-label/preview-mode-label.component';
import { SearchInputModule } from '@shared/components/search-input/search-input.module';
import { SignStepModalComponent } from '@shared/components/sign-step-modal/sign-step-modal.component';
import { SignStepModule } from '@shared/components/sign-step/sign-step.module';
import { TabsComponent } from '@shared/components/tabs/tabs.component';
import {
  TraceMatrixLinksSummarySectionModule
} from '@shared/components/trace-matrix-links/trace-matrix-links-summary-section/trace-matrix-links-summary-section.module';
import { WorkflowsListingModalModule } from '@shared/components/workflows-listing-modal/workflows-listing-modal.module';
import { DynamicModule } from 'ng-dynamic-component';
import { InViewportModule } from 'ng-in-viewport';
import { ImpactAssessmentCellComponent } from '../../canvas/shared/fe-components/table/impact-assessment-cell/impact-assessment-cell.component';
import { TmHyperlinksCellComponent } from '../../canvas/shared/fe-components/table/tm-hyperlinks-cell/tm-hyperlinks-cell.component';
import { WebViewerModule } from '../../webviewer/webviewer.module';
import { AsyncPdfBannerModule } from './async-pdf-banner/async-pdf-banner.module';
import { BulkResetModalComponent } from './bulk-reset/bulk-reset.component';
import { AddViewAttachmentModalComponent } from './canvas-table-preview/attachment-modal/add-view-attachment-modal.component';
import { DeviationDetailsModalComponent } from './canvas-table-preview/deviation-details-modal/deviation-details-modal.component';
import { TablePreviewComponent } from './canvas-table-preview/table-preview.component';
import { CompanyUsersGroupsModule } from './company-user-groups/company-user-groups.module';
import { ConfirmPasswordResetModalModule } from './confirm-password-reset-modal/confirm-password-reset-modal.module';
import { CreateTemplateModalComponent } from './create-template-modal/create-template-modal.component';
import { DatepickerModule } from './datepicker/datepicker.module';
import { ElectronicSignatureModule } from './electronic-signature/electronic-signature.module';
import { EmailNotificationModule } from './email-notification/email-notification.module';
import { FilterTagsModule } from './filter-tags/filter-tags.module';
import { HeaderLockComponent } from './header-lock/header-lock.component';
import { HeaderComponent } from './header/header.component';
import { IconTwoImagesComponent } from './icon-two-images/icon-two-images.component';
import { ImageUploadModule } from './image-upload/image-upload.module';
import { ImplementationModule } from './implementation/implementation.module';
import { InputsModule } from './inputs/inputs.module';
import { LinksComponent } from './links/links.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { MultiStepConfirmationModule } from './multi-step-confirmation/multi-step-confirmation.module';
import { MultiStepUploadFromCsvModule } from './multi-step-upload-from-csv/multi-step-upload-from-csv.module';
import { NotificationBulletComponent } from './notification-bullet/notification-bullet.component';
import { NotificationMenuItemComponent } from './notifications-menu/notification-menu-item/notification-menu-item.component';
import { NotificationsMenuComponent } from './notifications-menu/notifications-menu.component';
import { AclRequestAccessComponent } from './notifications-menu/templates/acl-request-access/acl-request-access.component';
import { AclRequestGrantedComponent } from './notifications-menu/templates/acl-request-granted/acl-request-granted.component';
import { AclRequestRejectedComponent } from './notifications-menu/templates/acl-request-rejected/acl-request-rejected.component';
import { ImportStepDataCompletedComponent } from './notifications-menu/templates/import-step-data-completed/import-step-data-completed.component';
import { CompanyLicenceRequestNotificationClosedComponent } from './notifications-menu/templates/licence-company-request-closed/licence-company-request-closed.component';
import { CompanyLicenceRequestNotificationRejectedComponent } from './notifications-menu/templates/licence-company-request-rejected/licence-company-request-rejected.component';
import { CompanyLicenceRequestNotificationSubmittedComponent } from './notifications-menu/templates/licence-company-request-submitted/licence-company-request-submitted.component';
import { LicenceRequestNotificationAcceptedInitiatorComponent } from './notifications-menu/templates/licence-request-accepted-initiator/licence-request-accepted-initiator.component';
import { LicenceRequestNotificationRejectedInitiatorComponent } from './notifications-menu/templates/licence-request-rejected-initiator/licence-request-rejected-initiator.component';
import { MarketDataImportedComponent } from './notifications-menu/templates/market-data-imported/market-data-imported.component';
import { MarketGlobalSystemComponent } from './notifications-menu/templates/market-global-system/market-global-system.component';
import { MarketPackageApprovedComponent } from './notifications-menu/templates/market-package-approved/market-package-approved.component';
import { MarketPackageDownloadedComponent } from './notifications-menu/templates/market-package-downloaded/market-package-downloaded.component';
import { MarketPackageLinkInitiatorComponent } from './notifications-menu/templates/market-package-link-initiator/market-package-link-initiator.component';
import {
  MarketPackagePublishedComponent
} from './notifications-menu/templates/market-package-published/market-package-published.component';
import { MarketPackageRejectedComponent } from './notifications-menu/templates/market-package-rejected/market-package-rejected.component';
import { MarketPackageRemovedOrChangedComponent } from './notifications-menu/templates/market-package-removed-or-changed/market-package-removed-or-changed.component';
import { MarketRelationCreatedComponent } from './notifications-menu/templates/market-relation-created/market-relation-created.component';
import { MarketRelationRemovedComponent } from './notifications-menu/templates/market-relation-removed/market-relation-removed.component';
import { MarketUserAssignedComponent } from './notifications-menu/templates/market-user-assigned/market-user-assigned.component';
import { MiscChangeUserRoleComponent } from './notifications-menu/templates/misc-change-user-role/misc-change-user-role.component';
import { MiscInitiatorSentMessageComponent } from './notifications-menu/templates/misc-initiator-sent-message/misc-initiator-sent-message.component';
import { UserReachedFailedLoginsLimitComponent } from './notifications-menu/templates/misc-user-reached-failed-logins-limit/misc-user-reached-failed-logins-limit.component';
import { ReportingActionFailComponent } from './notifications-menu/templates/reporting-action-fail/reporting-action-fail.component';
import {
  ReportingActionSuccessComponent
} from './notifications-menu/templates/reporting-action-success/reporting-action-success.component';
import { ReviewRequestCancelledToOwnerComponent } from './notifications-menu/templates/review-request-cancelled-to-owner/review-request-cancelled-to-owner.component';
import { ReviewRequestCancelledToRemainingReviewerComponent } from './notifications-menu/templates/review-request-cancelled-to-remaining-reviewer/review-request-cancelled-to-remaining-reviewer.component';
import { ReviewRequestChangedCancelledComponent } from './notifications-menu/templates/review-request-changed-cancelled/review-request-changed-cancelled.component';
import { ReviewRequestChangedComponent } from './notifications-menu/templates/review-request-changed/review-request-changed.component';
import {
  ReviewRequestCompletedComponent
} from './notifications-menu/templates/review-request-completed/review-request-completed.component';
import { ReviewRequestSentForReviewToReviewerComponent } from './notifications-menu/templates/review-request-sent-for-review-to-reviewer/review-request-sent-for-review-to-reviewer.component';
import { ExecutionRequestSentForExecutionToTesterComponent } from './notifications-menu/templates/execution-request-sent-for-execution-to-tester/execution-request-sent-for-execution-to-tester.component';
import { SignatureRequestChangedComponent } from './notifications-menu/templates/signature-request-changed/signature-request-changed.component';
import { SignatureRequestCompletedComponent } from './notifications-menu/templates/signature-request-completed/signature-request-completed.component';
import { SignatureRequestRejectedToRemainingApproverComponent } from './notifications-menu/templates/signature-request-rejected-to-remaining-approver/signature-request-rejected-to-remaining-approver.component';
import { SignatureRequestRejectedComponent } from './notifications-menu/templates/signature-request-rejected/signature-request-rejected.component';
import { SignatureRequestSentForSignatureComponent } from './notifications-menu/templates/signature-request-sent-for-signature/signature-request-sent-for-signature.component';
import { SignatureRequestApprovedOrCancelledComponent } from './notifications-menu/templates/signature-request-signed-or-cancelled/signature-request-signed-or-cancelled.component';
import { SignatureRequestUserRemovedComponent } from './notifications-menu/templates/signature-request-user-removed/signature-request-user-removed.component';
import { ScreenOverlayModule } from './overlay/screen-overlay.module';
import { PageLoadingOverlayModule } from './page-loading-overlay/page-loading-overlay.module';
import { PassFailCellModule } from './pass-fail-cell/pass-fail-cell.module';
import { PinCodeInputModule } from './pin-code-input/pin-code-input.module';
import { PopupModule } from './popup/popup.module';
import { CellHighlightModule } from './review-cell-highlight/cell-highlight.module';
import { TextHighlightModule } from './review-text-highlight/text-highlight.module';
import { SecondaryNavComponent } from './secondary-nav/secondary-nav.component';
import { SelectModule } from './select/select.module';
import { SettingsMetadataModule } from './settings-metadata/settings-metadata.module';
import { SidenavSecondaryModule } from './sidenav-secondary/sidenav-secondary.module';
import { SignaturePasswordBannerModule } from './signature-password-banner/signature-password-banner.module';
import { SubmitDeviationModalComponent } from './submit-deviation-modal/submit-deviation-modal.component';
import { TableLoadingOverlayModule } from './table-loading-overlay/table-loading-overlay.module';
import { TypeToConfirmModule } from './type-to-confirm/type-to-confirm.module';
import { UndoNotificationModule } from './undo-notification/undo-notification.module';
import { UserAvatarModule } from './user-avatar/user-avatar.module';
import { UserListingModalModule } from './user-listing-modal/user-listing-modal.module';
import { UserGroupDepartmentSearchModule } from './user-group-department-search/user-group-department-search.module';

import { FileThumbnailListModule } from './file-thumbnail-list/file-thumbnail-list.module';
import { FileThumbnailOrIconModule } from './file-thumbnail-or-icon/file-thumbnail-or-icon.module';
import { MetadataPanelModule } from './settings-metadata/metadata-panel/metadata-panel.module';
import { ScreenCaptureModalComponent } from './screen-capture-modal/screen-capture-modal.component';
import { RowAttachmentsModule } from './row-attachments/row-attachments.module';
import { ReportCompletedComponent } from './notifications-menu/templates/report-completed/report-completed.component';
import { SystemPeriodicReviewComponent } from './notifications-menu/templates/system-periodic-review/system-periodic-review.component';
import { ExecutionRequestChangedComponent } from './notifications-menu/templates/execution-request-changed/execution-request-changed.component';
import { ExecutionRequestChangedCancelledComponent } from './notifications-menu/templates/execution-request-changed-cancelled/execution-request-changed-cancelled.component';
import { ExecutionRequestCompletedSentForReviewComponent } from '@shared/components/notifications-menu/templates/execution-request-completed-sent-for-review/execution-request-completed-sent-for-review.component';
import { ExecutionRequestCompletedSentForSignatureComponent } from '@shared/components/notifications-menu/templates/execution-request-completed-sent-for-signature/execution-request-completed-sent-for-signature.component';
import { ExecutionRequestCancelledToOwnerComponent } from './notifications-menu/templates/execution-request-cancelled-to-owner/execution-request-cancelled-to-owner.component';
import { ExecutionRequestCancelledToRemainingTesterComponent } from './notifications-menu/templates/execution-request-cancelled-to-remaining-tester/execution-request-cancelled-to-remaining-tester.component';
import { DisableKPICardModule } from './disable-kpi-card/disable-kpi-card.module';
import { ScreenCaptureTimerComponent } from './screen-capture-modal/screen-capture-timer/screen-capture-timer.component';
import { KnowledgeHubModalComponent } from './knowledge-hub-modal/knowledge-hub-modal.component';
import { VimeoVideoComponent } from './vimeo-video/vimeo-video.component';
import { ExecutionRequestCanceledByOnlyOneUserComponent } from './notifications-menu/templates/execution-request-canceled-by-only-one-user/execution-request-canceled-by-only-one-user.component';
import { ReviewRequestCanceledByOnlyOneUserComponent } from './notifications-menu/templates/review-request-canceled-by-only-one-user/review-request-canceled-by-only-one-user.component';
import { SignatureRequestCanceledByOnlyOneUserComponent } from './notifications-menu/templates/signature-request-canceled-by-only-one-user/signature-request-canceled-by-only-one-user.component';

@NgModule({
  imports: [
    OverlayModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    ImageUploadModule,
    ReactiveFormsModule,
    FormsModule,
    CoreModule,
    UserAvatarModule,
    UserListingModalModule,
    UserGroupDepartmentSearchModule,
    PopupModule,
    EmailNotificationModule,
    MetadataPanelModule,
    UndoNotificationModule,
    TypeToConfirmModule,
    ObserversModule,
    MatExpansionModule,
    ButtonsModule,
    InputsModule,
    FileUploadModule,
    ImpactAssessmentModule,
    WorkflowsListingModalModule,
    LoadingAnimationModule,
    InViewportModule,
    ImplementationModule,
    SelectModule,
    DatepickerModule,
    CellHighlightModule,
    TextHighlightModule,
    DynamicModule,
    PageSelectorModule,
    FiltersDropdownModule,
    SearchInputModule,
    CustomStepperModule,
    AsyncPdfBannerModule,
    SignaturePasswordBannerModule,
    ScreenOverlayModule,
    SidenavSecondaryModule,
    CompanyUsersGroupsModule,
    TableLoadingOverlayModule,
    PageLoadingOverlayModule,
    ConfirmPasswordResetModalModule,
    MultiStepUploadFromCsvModule,
    TraceMatrixLinksSummarySectionModule,
    PassFailCellModule,
    MultiStepConfirmationModule,
    SignStepModule,
    PinCodeInputModule,
    ElectronicSignatureModule,
    SettingsMetadataModule,
    FilterTagsModule,
    WebViewerModule,
    FileThumbnailOrIconModule,
    FileThumbnailListModule,
    RowAttachmentsModule,
    DisableKPICardModule,
  ],
  declarations: [
    SecondaryNavComponent,
    MainNavComponent,
    NotificationsMenuComponent,
    CreateTemplateModalComponent,
    SubmitDeviationModalComponent,
    HeaderComponent,
    LinksComponent,
    TmHyperlinksCellComponent,
    TablePreviewComponent,
    DeviationDetailsModalComponent,
    ImpactAssessmentCellComponent,
    SignStepModalComponent,
    SignatureRequestApprovedOrCancelledComponent,
    SignatureRequestChangedComponent,
    SignatureRequestCompletedComponent,
    SignatureRequestRejectedComponent,
    SignatureRequestRejectedToRemainingApproverComponent,
    SignatureRequestUserRemovedComponent,
    SignatureRequestSentForSignatureComponent,
    ReviewRequestCancelledToOwnerComponent,
    ReviewRequestCancelledToRemainingReviewerComponent,
    ReviewRequestChangedCancelledComponent,
    ReviewRequestCompletedComponent,
    ReviewRequestSentForReviewToReviewerComponent,
    ReviewRequestChangedComponent,
    LicenceRequestNotificationAcceptedInitiatorComponent,
    CompanyLicenceRequestNotificationClosedComponent,
    CompanyLicenceRequestNotificationSubmittedComponent,
    CompanyLicenceRequestNotificationRejectedComponent,
    LicenceRequestNotificationRejectedInitiatorComponent,
    NotificationBulletComponent,
    NotificationMenuItemComponent,
    IconTwoImagesComponent,
    MiscInitiatorSentMessageComponent,
    MiscChangeUserRoleComponent,
    UserReachedFailedLoginsLimitComponent,
    PreviewModeLabelComponent,
    TabsComponent,
    MarketPackagePublishedComponent,
    MarketPackageDownloadedComponent,
    MarketPackageApprovedComponent,
    MarketPackageRemovedOrChangedComponent,
    MarketDataImportedComponent,
    MarketPackageLinkInitiatorComponent,
    MarketRelationCreatedComponent,
    MarketRelationRemovedComponent,
    MarketUserAssignedComponent,
    MarketGlobalSystemComponent,
    MarketPackageRejectedComponent,
    TraceMatrixGeneratedNotificationComponent,
    AddViewAttachmentModalComponent,
    KnowledgeHubModalComponent,
    HeaderLockComponent,
    BulkResetModalComponent,
    AclRequestAccessComponent,
    AclRequestGrantedComponent,
    AclRequestRejectedComponent,
    ImportStepDataCompletedComponent,
    ReportingActionSuccessComponent,
    ReportingActionFailComponent,
    ScreenCaptureModalComponent,
    ReportCompletedComponent,
    SystemPeriodicReviewComponent,
    ExecutionRequestSentForExecutionToTesterComponent,
    ExecutionRequestChangedComponent,
    ExecutionRequestChangedCancelledComponent,
    ExecutionRequestCompletedSentForReviewComponent,
    ExecutionRequestCompletedSentForSignatureComponent,
    ExecutionRequestCancelledToOwnerComponent,
    ExecutionRequestCancelledToRemainingTesterComponent,
    ScreenCaptureTimerComponent,
    VimeoVideoComponent,
    ExecutionRequestCanceledByOnlyOneUserComponent,
    ReviewRequestCanceledByOnlyOneUserComponent,
    SignatureRequestCanceledByOnlyOneUserComponent,
  ],
  exports: [
    SecondaryNavComponent,
    MainNavComponent,
    NotificationsMenuComponent,
    ImageUploadModule,
    UserAvatarModule,
    UserListingModalModule,
    UserGroupDepartmentSearchModule,
    HeaderComponent,
    PopupModule,
    LinksComponent,
    TmHyperlinksCellComponent,
    TablePreviewComponent,
    UndoNotificationModule,
    TypeToConfirmModule,
    ImpactAssessmentCellComponent,
    FileUploadModule,
    WorkflowsListingModalModule,
    LoadingAnimationModule,
    SelectModule,
    CellHighlightModule,
    TextHighlightModule,
    SignatureRequestApprovedOrCancelledComponent,
    SignatureRequestChangedComponent,
    SignatureRequestCompletedComponent,
    SignatureRequestRejectedComponent,
    SignatureRequestRejectedToRemainingApproverComponent,
    SignatureRequestUserRemovedComponent,
    SignatureRequestSentForSignatureComponent,
    ReviewRequestCancelledToOwnerComponent,
    ReviewRequestCancelledToRemainingReviewerComponent,
    ReviewRequestChangedCancelledComponent,
    ReviewRequestCompletedComponent,
    ReviewRequestSentForReviewToReviewerComponent,
    ReviewRequestChangedComponent,
    LicenceRequestNotificationAcceptedInitiatorComponent,
    CompanyLicenceRequestNotificationClosedComponent,
    CompanyLicenceRequestNotificationSubmittedComponent,
    CompanyLicenceRequestNotificationRejectedComponent,
    LicenceRequestNotificationRejectedInitiatorComponent,
    NotificationBulletComponent,
    NotificationMenuItemComponent,
    IconTwoImagesComponent,
    PreviewModeLabelComponent,
    TabsComponent,
    FiltersDropdownModule,
    SearchInputModule,
    CustomStepperModule,
    AsyncPdfBannerModule,
    SignaturePasswordBannerModule,
    ScreenOverlayModule,
    SidenavSecondaryModule,
    CompanyUsersGroupsModule,
    TableLoadingOverlayModule,
    PageLoadingOverlayModule,
    ConfirmPasswordResetModalModule,
    MultiStepUploadFromCsvModule,
    TraceMatrixGeneratedNotificationComponent,
    HeaderLockComponent,
    PinCodeInputModule,
    ElectronicSignatureModule,
    SettingsMetadataModule,
    FileThumbnailOrIconModule,
    FileThumbnailListModule,
    RowAttachmentsModule,
    ExecutionRequestSentForExecutionToTesterComponent,
    ExecutionRequestChangedComponent,
    ExecutionRequestChangedCancelledComponent,
    ExecutionRequestCompletedSentForReviewComponent,
    ExecutionRequestCompletedSentForSignatureComponent,
    ExecutionRequestCancelledToOwnerComponent,
    ExecutionRequestCancelledToRemainingTesterComponent,
    DisableKPICardModule,
    VimeoVideoComponent
  ],
  providers: []
})
export class ComponentsModule {
}
