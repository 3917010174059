import { Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-signature-request-canceled-by-only-one-user',
  templateUrl: './signature-request-canceled-by-only-one-user.component.html',
  styleUrls: ['./signature-request-canceled-by-only-one-user.component.scss']
})
export class SignatureRequestCanceledByOnlyOneUserComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
}