import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DEBOUNCE_TIME } from '@core/constants/app-constants';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class SearchInputComponent implements OnInit {
  @Input() value = '';
  @Input() placeholder = 'Search Title or Description';
  @Input() width = '250px';
  @Output() inputChanged = new EventEmitter();
  formControl = new UntypedFormControl('');
  private searchFormControl$: Subscription;

  ngOnInit() {
    if (this.value) {
      this.formControl.patchValue(this.value);
    }
    this.searchFormControl$ = this.formControl.valueChanges.pipe(
      debounceTime(DEBOUNCE_TIME),
    ).subscribe(res => this.inputChanged.emit(res));
  }

  clearSearch(): void {
    this.formControl.patchValue('');
    this.inputChanged.emit('');
  }
}
