import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { PageSelectorModule } from '@shared/components/page-selector/page-selector.module';
import { SharingInputModule } from '@shared/components/sharing-input/sharing-input.module';
import { TableLoadingOverlayModule } from '@shared/components/table-loading-overlay/table-loading-overlay.module';
import { SharedModule } from '@shared/shared.module';
import { LoginHeaderComponent } from './components/login-header/login-header.component';
import { CmsModule } from './pages/cms/cms.module';
import { CompanyNotFoundComponent } from './pages/company-not-found/company-not-found.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HomeComponent } from './pages/home/home.component';
import { SystemCardComponent } from './pages/home/system-card/system-card.component';
import { TaskCardComponent } from './pages/home/task-card/task-card.component';
import { WorkflowCardComponent } from './pages/home/workflow-card/workflow-card.component';
import { WorkflowCreateCardComponent } from './pages/home/workflow-create-card/workflow-create-card.component';
import { LoginComponent } from './pages/login/login.component';
import { MissingAccountLoginComponent } from './pages/missing-account/missing-account-login.component';
import { MissingAccountComponent } from './pages/missing-account/missing-account.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { DashboardModule } from './shared/dashboard/dashboard.module';
import { SharedLoginModule } from './shared/login/shared-login.module';
import { BlankPageComponent } from './pages/blank/blank.component';
import { KnowledgeHubTutorialsPageModule } from './pages/knowledge-hub-tutorials-page/knowledge-hub-tutorials-page.module';
import { KnowledgeHubTutorialVideosModule } from './shared/knowledge-hub-tutorial-videos/knowledge-hub-tutorial-videos.module';

@NgModule({
  imports: [
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    CmsModule,
    KnowledgeHubTutorialsPageModule,
    KnowledgeHubTutorialVideosModule,
    DashboardModule,
    ButtonsModule,
    RouterModule,
    SharingInputModule,
    SharedLoginModule,
    PageSelectorModule,
    TableLoadingOverlayModule,
    AmplifyAuthenticatorModule
  ],
  declarations: [
    LoginComponent,
    HomeComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PageNotFoundComponent,
    CompanyNotFoundComponent,
    TaskCardComponent,
    WorkflowCardComponent,
    SystemCardComponent,
    WorkflowCreateCardComponent,
    MissingAccountComponent,
    MissingAccountLoginComponent,
    LoginHeaderComponent,
    BlankPageComponent,
  ],
  exports: [
    LoginComponent,
    HomeComponent,
    ForgotPasswordComponent,
    CmsModule,
    KnowledgeHubTutorialsPageModule,
    KnowledgeHubTutorialVideosModule,
    ResetPasswordComponent,
    PageNotFoundComponent,
    CompanyNotFoundComponent,
    TaskCardComponent,
    WorkflowCardComponent,
    SystemCardComponent,
    BlankPageComponent,
  ]
})
export class AdminModule {
}
