import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-vimeo-video',
  templateUrl: './vimeo-video.component.html',
  styleUrls: ['./vimeo-video.component.scss']
})
export class VimeoVideoComponent implements OnChanges {
  @Input() videoUrl!: string;
  safeUrl: SafeResourceUrl = '';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.videoUrl?.currentValue) {
      this.safeUrl = this.composeVimeoUrl(changes?.videoUrl?.currentValue);
    }
  }

  private composeVimeoUrl(url: string) {
    const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)(?:\/([\w]+))?/;
    const match = url.match(vimeoRegex);
    if (match && match[1]) {
      const videoId = match[1]; // Extract video ID
      const unlistedHash = match[2] || ''; // Extract extra ID for unlisted videos - if any
      const vimeoUrl = unlistedHash 
        ? `https://player.vimeo.com/video/${videoId}?h=${unlistedHash}` 
        : `https://player.vimeo.com/video/${videoId}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(vimeoUrl);
    }
    return '';
  }
}