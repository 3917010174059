<mat-card appearance="outline" class="task-card {{tasks.value}} flex-100">
  <div class="card-header flex-100">
    <span class="tasks-count headline-3">{{tasks.count || tasks.items.length}}</span>
    <span class="task-card-label headline-5 margin-left-15">{{tasks.label}} tasks</span>
  </div>
  <ng-container *ngIf="tasks.items.length">
    <div class="content flex-100">
      <div class="tasks-card-container">
        <ng-container *ngFor="let task of tasks.items.slice(0, 3)">
          <div class="task-item-container" (click)="goToWorkflow(task)">
            <div class="task-title subtitle-2">
              {{task.workflow_title}}
            </div>
            <div class="task-subtitle caption">
              Needs your <span class="bold-text">{{task.type_label}}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!tasks.items.length">
    <div class="content flex-100">
      <div class="tasks-card-container">
        <span class="subtitle-2" *ngIf="tasks.value !== 'overdue'">We will let you know when new tasks are assigned to you</span>
        <span class="subtitle-2" *ngIf="tasks.value === 'overdue'">Keep up the good work!</span>
      </div>
    </div>
  </ng-container>
  <div class="card-actions flex-100">
    <div (click)="goToTasks(tasks.value)" class="view-all-link body-link">
      <span>View More</span>
      <span class="arrow-icon"><mat-icon class="right-arrow">arrow_forward</mat-icon></span>
    </div>
  </div>

</mat-card>