<div class="btr-modal-container">
  <span mat-dialog-title>
    <div class="flex-100 justify-between items-center">
      <span>Res_Q Tutorials</span>
    </div>
  </span>

  <mat-dialog-content class="flex-row">
    <app-loading-animation *ngIf="isLoading" [withLogo]="false" [size]="40"></app-loading-animation>

    <div *ngIf="!isLoading" class="vimeo-container">
      <app-vimeo-video [videoUrl]="videoUrl"></app-vimeo-video>
    </div>
    <div *ngIf="!isLoading" class="knowledge-hub-video-selection-container flex-col">
      <app-search-input
        class="justify-center search-input-margins"
        [value]="search"
        placeholder="Search"
        width="auto"
        (inputChanged)="filterValueChanged($event)">
      </app-search-input>
      <mat-accordion class="workflow-accordion" multi>
        <mat-expansion-panel *ngFor="let category of menuCategories" [expanded]="category.expanded" class="mat-expansion-panel-parent">
          <mat-expansion-panel-header class="panel-header align-center" (click)="category.expanded = !category.expanded">
            <mat-panel-title class="align-center">
              <div class="info-accordion-item-title accordion-title">
                <span class="workflow-item-title tst-panel-header-container">
                  <span class="panel-title">{{ category.title }}</span>
                </span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="thumbnail" *ngIf="category.items.length > 0">
            <div *ngFor="let item of category.items; let i = index" 
              class="image-container"
              [class.selected]="isThumbnailSelected(i, category.title)"
              (click)="selectThumbnail(i, category.title, item.video_link)">
              <img class="blurred-video-preview blurred-video-preview-category" [src]="'assets/img/blurred-video-preview.png'" alt="item"/>
              <div class="text-overlay">
                {{item.video_title}}
              </div>
            </div>
          </div>

          <div class="thumbnail" *ngIf="!category.items.length && !category.subcategories.length">
            <div class="image-container">
              <img class="blurred-video-preview blurred-video-preview-category" [src]="'assets/img/blurred-video-preview.png'" alt="item"/>
              <div class="text-overlay">
                Tutorial videos coming soon
              </div>
            </div>
          </div>
    
          <mat-accordion class="workflow-accordion" multi>
            <mat-expansion-panel *ngFor="let subcategory of category.subcategories" [expanded]="subcategory.expanded" class="mat-expansion-panel-child">
              <mat-expansion-panel-header class="panel-header align-center" (click)="subcategory.expanded = !subcategory.expanded">
                <mat-panel-title class="align-center">
                  <div class="info-accordion-item-title accordion-title">
                    <span class="workflow-item-title tst-panel-header-container">
                      <span class="panel-title">{{ subcategory.title }}</span>
                    </span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
    
              <div class="thumbnail" *ngIf="subcategory.items.length > 0">
                <div *ngFor="let item of subcategory.items; let i = index" 
                  class="image-container"
                  [class.selected]="isThumbnailSelected(i, subcategory.title)"
                  (click)="selectThumbnail(i, subcategory.title, item.video_link)">
                  <img class="blurred-video-preview blurred-video-preview-subcategory" [src]="'assets/img/blurred-video-preview.png'" alt="item"/>
                  <div class="text-overlay">
                    {{item.video_title}}
                  </div>
                </div>
              </div>

              <div class="thumbnail" *ngIf="!subcategory.items.length">
                <div class="image-container">
                  <img class="blurred-video-preview blurred-video-preview-category" [src]="'assets/img/blurred-video-preview.png'" alt="item"/>
                  <div class="text-overlay">
                    Tutorial videos coming soon
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <app-styleguide-simple-button
      type="dark-link"
      label="Close"
      (onClick)="cancel()">
    </app-styleguide-simple-button>
  </mat-dialog-actions>
</div>
