export const INPUT_ABBREVIATION_LENGTH = 3;
export const INPUT_PIN_CODE_LENGTH = 6;
export const INPUT_PREFIX_LENGTH = 10;
export const INPUT_TITLE_LENGTH = 40;
export const INPUT_LONG_TITLE_LENGTH = 150;
export const INPUT_NAME_LENGTH = 100;
export const INPUT_PHONE_LENGTH = 100;
export const INPUT_EMAIL_LENGTH = 254;
export const INPUT_CONTACT_LENGTH = 201;
export const INPUT_WEBSITE_LENGTH = 100;
export const INPUT_MEDIUM_LENGTH = 1000;
export const INPUT_LONG_LENGTH = 2000;
export const INPUT_LABEL_LENGTH = 20;
