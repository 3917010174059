import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { NotificationResponse } from '@core/interfaces';
import { Company } from '@core/models';
import { CompaniesService, NotificationsService, RoutingService, WebsocketService } from '@core/services';
import { StateService } from '@core/services/state.service';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { Select, Store } from '@ngxs/store';
import {
  TraceMatrixGeneratedNotificationComponent
} from '@shared/components/notifications-menu/templates/trace-matrix-generated/trace-matrix-generated.component';
import { CompanySelectors } from '@store/company/company.selectors';
import { UserSelectors } from '@store/user/user.selectors';
import * as _ from 'lodash';
import moment from 'moment';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { GetNotifications, PushNotification, UpdateNotificationsCounter } from 'src/app/store/notification/notification.actions';
import { NotificationSelectors } from 'src/app/store/notification/notification.selectors';
import { SignatureRequestApprovedOrCancelledComponent } from './templates/signature-request-signed-or-cancelled/signature-request-signed-or-cancelled.component';
import { SignatureRequestChangedComponent } from './templates/signature-request-changed/signature-request-changed.component';
import { SignatureRequestCompletedComponent } from './templates/signature-request-completed/signature-request-completed.component';
import { SignatureRequestRejectedToRemainingApproverComponent } from './templates/signature-request-rejected-to-remaining-approver/signature-request-rejected-to-remaining-approver.component';
import { SignatureRequestRejectedComponent } from './templates/signature-request-rejected/signature-request-rejected.component';
import { SignatureRequestSentForSignatureComponent } from './templates/signature-request-sent-for-signature/signature-request-sent-for-signature.component';
import { SignatureRequestUserRemovedComponent } from './templates/signature-request-user-removed/signature-request-user-removed.component';
import { CompanyLicenceRequestNotificationClosedComponent } from './templates/licence-company-request-closed/licence-company-request-closed.component';
import { CompanyLicenceRequestNotificationRejectedComponent } from './templates/licence-company-request-rejected/licence-company-request-rejected.component';
import { CompanyLicenceRequestNotificationSubmittedComponent } from './templates/licence-company-request-submitted/licence-company-request-submitted.component';
import { LicenceRequestNotificationAcceptedInitiatorComponent } from './templates/licence-request-accepted-initiator/licence-request-accepted-initiator.component';
import { LicenceRequestNotificationRejectedInitiatorComponent } from './templates/licence-request-rejected-initiator/licence-request-rejected-initiator.component';
import { MarketDataImportedComponent } from './templates/market-data-imported/market-data-imported.component';
import { MarketGlobalSystemComponent } from './templates/market-global-system/market-global-system.component';
import { MarketPackageApprovedComponent } from './templates/market-package-approved/market-package-approved.component';
import { MarketPackageLinkInitiatorComponent } from './templates/market-package-link-initiator/market-package-link-initiator.component';
import { MarketPackagePublishedComponent } from './templates/market-package-published/market-package-published.component';
import { MarketPackageRejectedComponent } from './templates/market-package-rejected/market-package-rejected.component';
import { MarketPackageRemovedOrChangedComponent } from './templates/market-package-removed-or-changed/market-package-removed-or-changed.component';
import { MarketRelationCreatedComponent } from './templates/market-relation-created/market-relation-created.component';
import { MarketRelationRemovedComponent } from './templates/market-relation-removed/market-relation-removed.component';
import { MarketUserAssignedComponent } from './templates/market-user-assigned/market-user-assigned.component';
import { MiscChangeUserRoleComponent } from './templates/misc-change-user-role/misc-change-user-role.component';
import { MiscInitiatorSentMessageComponent } from './templates/misc-initiator-sent-message/misc-initiator-sent-message.component';
import { UserReachedFailedLoginsLimitComponent } from './templates/misc-user-reached-failed-logins-limit/misc-user-reached-failed-logins-limit.component';
import { ReviewRequestCancelledToOwnerComponent } from './templates/review-request-cancelled-to-owner/review-request-cancelled-to-owner.component';
import { ReviewRequestCancelledToRemainingReviewerComponent } from './templates/review-request-cancelled-to-remaining-reviewer/review-request-cancelled-to-remaining-reviewer.component';
import { ReviewRequestChangedCancelledComponent } from './templates/review-request-changed-cancelled/review-request-changed-cancelled.component';
import { ReviewRequestChangedComponent } from './templates/review-request-changed/review-request-changed.component';
import { ReviewRequestCompletedComponent } from './templates/review-request-completed/review-request-completed.component';
import { ReviewRequestSentForReviewToReviewerComponent } from './templates/review-request-sent-for-review-to-reviewer/review-request-sent-for-review-to-reviewer.component';
import { MarketPackageDownloadedComponent } from './templates/market-package-downloaded/market-package-downloaded.component';
import { AclRequestAccessComponent } from './templates/acl-request-access/acl-request-access.component';
import { AclRequestGrantedComponent } from './templates/acl-request-granted/acl-request-granted.component';
import { AclRequestRejectedComponent } from './templates/acl-request-rejected/acl-request-rejected.component';
import { ImportStepDataCompletedComponent } from './templates/import-step-data-completed/import-step-data-completed.component';
import { ReportingActionSuccessComponent } from './templates/reporting-action-success/reporting-action-success.component';
import { ReportingActionFailComponent } from './templates/reporting-action-fail/reporting-action-fail.component';
import { AUTOLOGOUT_SESSION_ID, WEBSOCKET_CHANNELS, WEBSOCKET_EVENTS } from '@core/constants/app-constants';
import { Logout } from '@store/user/user.actions';
import { ReportCompletedComponent } from './templates/report-completed/report-completed.component';
import { SystemPeriodicReviewComponent } from './templates/system-periodic-review/system-periodic-review.component';
import { ExecutionRequestSentForExecutionToTesterComponent } from './templates/execution-request-sent-for-execution-to-tester/execution-request-sent-for-execution-to-tester.component';
import { ExecutionRequestChangedComponent } from './templates/execution-request-changed/execution-request-changed.component';
import { ExecutionRequestChangedCancelledComponent } from './templates/execution-request-changed-cancelled/execution-request-changed-cancelled.component';
import { ExecutionRequestCompletedSentForReviewComponent } from '@shared/components/notifications-menu/templates/execution-request-completed-sent-for-review/execution-request-completed-sent-for-review.component';
import { ExecutionRequestCompletedSentForSignatureComponent } from '@shared/components/notifications-menu/templates/execution-request-completed-sent-for-signature/execution-request-completed-sent-for-signature.component';
import { ExecutionRequestCancelledToOwnerComponent } from './templates/execution-request-cancelled-to-owner/execution-request-cancelled-to-owner.component';
import { ExecutionRequestCancelledToRemainingTesterComponent } from './templates/execution-request-cancelled-to-remaining-tester/execution-request-cancelled-to-remaining-tester.component';
import { ExecutionRequestCanceledByOnlyOneUserComponent } from './templates/execution-request-canceled-by-only-one-user/execution-request-canceled-by-only-one-user.component';
import { ReviewRequestCanceledByOnlyOneUserComponent } from './templates/review-request-canceled-by-only-one-user/review-request-canceled-by-only-one-user.component';
import { SignatureRequestCanceledByOnlyOneUserComponent } from './templates/signature-request-canceled-by-only-one-user/signature-request-canceled-by-only-one-user.component';

@Component({
  selector: 'app-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class NotificationsMenuComponent implements OnInit, AfterViewInit {
  // TODO - is this notification observable closed on logout?
  // sometimes on logout there's an error in the console : dateToDay pipe crashes
  // from date-to-day.pipe.ts : const companyConfig = this.store.selectSnapshot(CompanySelectors.getCurrentCompany).config;
  // most likely the selector returns null/undefined because of logout
  // but this component shouldn't crash as on logout/onDestroy this subscription should be closed

  @Input() notifications$: Observable<NotificationResponse[]>;
  @ViewChild('notifRef') notifRef: ElementRef;

  @Select(NotificationSelectors.isLoading)
  public isLoading$: Observable<boolean>;

  filteredNotifications$: Observable<any>;

  private scroll$: Subscription;

  @Select(CompanySelectors.getCurrentCompany)
  company$: Observable<Company>;

  isBtrAdmin: boolean;
  isBtrAdmin$: Subscription;

  private user$: Subscription;

  components = {
    SignatureRequestApproved: SignatureRequestApprovedOrCancelledComponent,
    SignatureRequestCancelled: SignatureRequestApprovedOrCancelledComponent,
    SignatureRequestChanged: SignatureRequestChangedComponent,
    SignatureRequestCompleted: SignatureRequestCompletedComponent,
    SignatureRequestRejected: SignatureRequestRejectedComponent,
    SignatureRequestRejectedToRemainingApprover: SignatureRequestRejectedToRemainingApproverComponent,
    SignatureRequestUserRemoved: SignatureRequestUserRemovedComponent,
    SignatureRequestSentForSignature: SignatureRequestSentForSignatureComponent,
    ReviewRequestCancelledToOwner: ReviewRequestCancelledToOwnerComponent,
    ReviewRequestCancelledToRemainingReviewer: ReviewRequestCancelledToRemainingReviewerComponent,
    ReviewRequestChangedCancelled: ReviewRequestChangedCancelledComponent,
    ReviewRequestCompleted: ReviewRequestCompletedComponent,
    ReviewRequestSentForReviewToReviewer: ReviewRequestSentForReviewToReviewerComponent,
    ReviewRequestChanged: ReviewRequestChangedComponent,
    LicenceRequestNotificationAcceptedInitiator: LicenceRequestNotificationAcceptedInitiatorComponent,
    CompanyLicenceRequestNotificationClosed: CompanyLicenceRequestNotificationClosedComponent,
    CompanyLicenceRequestNotificationSubmitted: CompanyLicenceRequestNotificationSubmittedComponent,
    CompanyLicenceRequestNotificationRejected: CompanyLicenceRequestNotificationRejectedComponent,
    LicenceRequestNotificationRejectedInitiator: LicenceRequestNotificationRejectedInitiatorComponent,
    WorkflowVersionsReport: MiscInitiatorSentMessageComponent,
    UserRoleChanged: MiscChangeUserRoleComponent,
    UserReachedFailedLoginsLimit: UserReachedFailedLoginsLimitComponent,
    PackagePublishedPublicToBtrAdmin: MarketPackagePublishedComponent,
    PackagePublishedPrivateToBtrAdmin: MarketPackagePublishedComponent,
    PackageApprovedToCompany: MarketPackageApprovedComponent,
    PackageRejectedPublicToCompany: MarketPackageRejectedComponent,
    PackageRejectedPrivateToCompany: MarketPackageRejectedComponent,
    PackageRemovedToParentCompany: MarketPackageRemovedOrChangedComponent,
    PackageRemovedByCompany: MarketPackageRemovedOrChangedComponent,
    PackagesRemovedDueToGlobalSystemDelete: MarketPackageRemovedOrChangedComponent,
    PackagePublishedPublicToCompany: MarketPackagePublishedComponent,
    PackagePublishedPrivateToCompany: MarketPackagePublishedComponent,
    PackageChangedByCompany: MarketPackageRemovedOrChangedComponent,
    DataImportedSuccessfully: MarketDataImportedComponent,
    PackageLinkWithInitiator: MarketPackageLinkInitiatorComponent,
    RelationCreatedToParentCompany: MarketRelationCreatedComponent,
    RelationCreatedToChildCompany: MarketRelationCreatedComponent,
    RelationRemovedToParentCompany: MarketRelationRemovedComponent,
    RelationRemovedToChildCompany: MarketRelationRemovedComponent,
    UserAssignedToChildCompany: MarketUserAssignedComponent,
    GlobalSystemDeleted: MarketGlobalSystemComponent,
    GlobalSystemActivatedToCompany: MarketGlobalSystemComponent,
    CompletedTraceMatrixGeneration: TraceMatrixGeneratedNotificationComponent,
    CompletedTraceMatrixUpdate: TraceMatrixGeneratedNotificationComponent,
    PackageDownloadedToCompany: MarketPackageDownloadedComponent,
    WorkflowAccessListRequested: AclRequestAccessComponent,
    WorkflowAccessListApproved: AclRequestGrantedComponent,
    WorkflowAccessListRejected: AclRequestRejectedComponent,
    CompletedStepDataImport: ImportStepDataCompletedComponent,
    ReportingEnabledSuccessful: ReportingActionSuccessComponent,
    ReportingEnabledFailed: ReportingActionFailComponent,
    ReportingSyncSuccessful: ReportingActionSuccessComponent,
    ReportingSyncFailed: ReportingActionFailComponent,
    ReportCompleted: ReportCompletedComponent,
    SystemPeriodicReview: SystemPeriodicReviewComponent,

    ExecutionRequestSentForExecutionToTester: ExecutionRequestSentForExecutionToTesterComponent,
    ExecutionRequestChanged: ExecutionRequestChangedComponent,
    ExecutionRequestChangedCancelled: ExecutionRequestChangedCancelledComponent,
    ExecutionRequestCompletedSendForReviewToOwner: ExecutionRequestCompletedSentForReviewComponent,
    ExecutionRequestCompletedSendForSignatureToOwner: ExecutionRequestCompletedSentForSignatureComponent,
    ExecutionRequestCancelledToOwner: ExecutionRequestCancelledToOwnerComponent,
    ExecutionRequestCancelledToRemainingTesters: ExecutionRequestCancelledToRemainingTesterComponent,

    ExecutionRequestCancelledByOnlyOneUser: ExecutionRequestCanceledByOnlyOneUserComponent,
    ReviewRequestCancelledByOnlyOneUser: ReviewRequestCanceledByOnlyOneUserComponent,
    SignatureRequestCancelledByOnlyOneUser: SignatureRequestCanceledByOnlyOneUserComponent,
  };

  constructor(
    public router: Router,
    public stateService: StateService,
    public notificationsService: NotificationsService,
    public companiesService: CompaniesService,
    public routingService: RoutingService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private websocketService: WebsocketService,
  ) {
  }

  ngOnInit() {
    this.user$ = this.store.select(UserSelectors.getCurrentUser).pipe(filter(result => !!result)).subscribe(user => {
      this.initWebsocket(user);
    });

    this.isBtrAdmin$ = this.store.select(UserSelectors.isBTRAdmin).subscribe(isBtrAdmin => {
      this.isBtrAdmin = isBtrAdmin;
      this.cdr.markForCheck();
    });

    this.filteredNotifications$ = this.notifications$.pipe(
      map(notif => {
        //  Group the data by date
        const grouped = _.groupBy(notif, (n: NotificationResponse) => moment(n.created_at).format('DD-MMM-YYYY'));
        const arr = [];
        //  Build the array to be used in the view
        for (const g in grouped) {
          if (grouped.hasOwnProperty(g)) {
            arr.push({ date: g, details: grouped[g] });
          }
        }
        return arr;
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.notifRef && this.notifRef.nativeElement) {
      this.scroll$ = fromEvent(this.notifRef.nativeElement, 'scroll').pipe(
        map((res: MouseEvent) => res.target)
      ).subscribe((target: any) => {
        const canRetrieveNotifications = this.store.selectSnapshot(NotificationSelectors.canRetrieveNotifications);
        const isLoading = this.store.selectSnapshot(NotificationSelectors.isLoading);
        if (canRetrieveNotifications && !isLoading) {
          if (target.scrollTop + target.clientHeight >= target.scrollHeight - 40) {
            this.store.dispatch(new GetNotifications());
          }
        }
      });
    }
  }

  goToSettings(): void {
    this.router.navigate(['/profile/user-notification-settings']);
  }

  initWebsocket(user): void {
    this.websocketService.connectionEstablished$.pipe(filter(socketId => !!socketId)).subscribe((socketId: string) => {
      const authChannelName = `private-users.notifications.${user.uuid}`;
      this.websocketService.authWebsocket(socketId, authChannelName).subscribe((e) => {
        const subscribeChannelName = `${WEBSOCKET_CHANNELS.USERS_NOTIFICATIONS}.${user.uuid}`;
        this.websocketService.subscribeToChannel(e.auth, subscribeChannelName);
      })
      const autoLogoutSessionId = localStorage.getItem(AUTOLOGOUT_SESSION_ID);
      const autoLogoutChannelName = `${WEBSOCKET_CHANNELS.AUTOLOGOUT}.${autoLogoutSessionId}`;
      this.websocketService.subscribeToChannel(null, autoLogoutChannelName);
    });

    this.websocketService.newMessage$
    .pipe(filter(x => !!x))
    .subscribe((message: any) => {
      if (message.event === WEBSOCKET_EVENTS.BROADCAST_NOTIFICATION_CREATED) {
        this.store.dispatch(new PushNotification(JSON.parse(message.data)));
      }

      if (message.event === WEBSOCKET_EVENTS.BROADCAST_NOTIFICATION_COUNTER_UPDATED) {
        this.store.dispatch(new UpdateNotificationsCounter(JSON.parse(message.data).counter));
      }

      if (message.event === WEBSOCKET_EVENTS.BROADCAST_NOTIFICATION_LOGOUT) {
        this.store.dispatch(new Logout(true));
      }
    })
  }
}
