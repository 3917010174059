import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserSelectors } from '@store/user/user.selectors';
import { Tutorial, KnowledgeHubTutorialModalModes, MenuCategoryAdminTables, CategoryKeyMapping } from '../../../core/interfaces';
import { KNOWLEDGE_HUB_ADMIN_TABLES } from '../../../core/constants/app-constants';
import { ConfirmationModalComponent } from '@core/modals';
import { MatDialog } from '@angular/material/dialog';
import { filter, switchMap, take, tap } from 'rxjs';
import { AddEditRenameVideoComponent } from './add-edit-rename-video/add-edit-rename-video.component';
import { HelperService, KnowledgeHubService } from '../../../core/services';
import { CompanySelectors } from '@store/company/company.selectors';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-knowledge-hub-tutorial-videos',
  templateUrl: './knowledge-hub-tutorial-videos.component.html',
  styleUrls: ['./knowledge-hub-tutorial-videos.component.scss']
})
export class KnowledgeHubTutorialVideosComponent implements OnInit {
  @Input() isDefaultTutorials = false;

  knowledgeHubAdminTables: MenuCategoryAdminTables[] = KNOWLEDGE_HUB_ADMIN_TABLES;
  isBTRAdmin = false;
  isBTRAdminInCompanySettings = false;
  isLoading = false;
  columnsToDisplay = ['video_title', 'video_link', 'actions'];
  companyUuid = '';

  readonly categoryKeyMapping = CategoryKeyMapping;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private knowledgeHubService: KnowledgeHubService,
    private helperService: HelperService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.isBTRAdmin = this.store.selectSnapshot(UserSelectors.isBTRAdmin);
    this.isBTRAdminInCompanySettings = this.isBTRAdmin && !this.isDefaultTutorials;
    this.companyUuid = this.store.selectSnapshot(CompanySelectors.getCurrentCompany).uuid;
    this.getTutorials();
  }

  addVideo(category: string): void {
    this.dialog.open(AddEditRenameVideoComponent, {
      data: {
        category,
        mode: KnowledgeHubTutorialModalModes.Add
      }
    }).afterClosed().pipe(
      filter(res => !!res),
      tap(() => {
        this.isLoading = true;
      }),
      switchMap((res) => this.isDefaultTutorials 
        ? this.knowledgeHubService.addKnowledgeHubTutorialBTRADefaultTutorials(res)
        : this.knowledgeHubService.addKnowledgeHubTutorial(res)),
      take(1)
    ).subscribe(() => {
      this.getTutorials();
    });
  }

  resetVideo(item: Tutorial): void {
    const btrTutorialUuid = item.btr_tutorial_uuid;
    this.dialog.open(ConfirmationModalComponent,
      {
        data: {
          modalTitle: `Reset to default`,
          modalContent: `Are you sure you want to reset this video's link and title to default?`,
          confirmButtonLabel: 'Reset',
          cancelButtonLabel: 'Cancel',
          confirmButtonType: 'dark-link'
        }
      }).afterClosed().pipe(
        filter(res => !!res),
        tap(() => {
          this.isLoading = true;
        }),
        switchMap(() => this.knowledgeHubService.resetKnowledgeHubTutorial(btrTutorialUuid)),
        take(1)
      ).subscribe((res: any) => {
        if (res?.show_toaster){
          this.toastr.success(res?.toaster_message);
        }

        this.getTutorials();
      });
  }

  renameVideo(item: Tutorial): void {
    this.dialog.open(AddEditRenameVideoComponent, {
      data: {
        item,
        mode: KnowledgeHubTutorialModalModes.Rename
      }
    }).afterClosed().pipe(
      filter(res => !!res),
      tap(() => {
        this.isLoading = true;
      }),
      switchMap((res) => this.isDefaultTutorials 
        ? this.knowledgeHubService.updateKnowledgeHubTutorialBTRADefaultTutorials(res)
        : this.knowledgeHubService.updateKnowledgeHubTutorial(res)),
      take(1)
    ).subscribe(() => {
      this.getTutorials();
    });
  }

  editVideoLink(item: Tutorial): void {
    this.dialog.open(AddEditRenameVideoComponent, {
      data: {
        item,
        mode: KnowledgeHubTutorialModalModes.Edit
      }
    }).afterClosed().pipe(
      filter(res => !!res),
      tap(() => {
        this.isLoading = true;
      }),
      switchMap((res) => this.isDefaultTutorials 
        ? this.knowledgeHubService.updateKnowledgeHubTutorialBTRADefaultTutorials(res)
        : this.knowledgeHubService.updateKnowledgeHubTutorial(res)),
      take(1)
    ).subscribe(() => {
      this.getTutorials();
    });
  }

  deleteVideo(item: Tutorial): void {
    this.dialog.open(ConfirmationModalComponent,
      {
        data: {
          modalTitle: `Delete video`,
          modalContent: `Are you sure you want to delete this video?`,
          confirmButtonLabel: 'Delete',
          cancelButtonLabel: 'Cancel',
          confirmButtonType: 'red-link'
        }
      }).afterClosed().pipe(
        filter(res => !!res),
        tap(() => {
          this.isLoading = true;
        }),
        switchMap(() => this.isDefaultTutorials 
          ? this.knowledgeHubService.deleteKnowledgeHubTutorialBTRADefaultTutorials(item)
          : this.knowledgeHubService.deleteKnowledgeHubTutorial(item)),
        take(1)
      ).subscribe(() => {
        this.getTutorials();
      });
  }

  private getTutorials(): void {
    this.isLoading = true;

    const req = this.isDefaultTutorials 
      ? this.knowledgeHubService.getKnowledgeHubTutorialsBTRADefaultTutorials()
      : this.isBTRAdmin 
        ? this.knowledgeHubService.getKnowledgeHubTutorialsBTRAdminCompany(this.companyUuid)
        : this.knowledgeHubService.getKnowledgeHubTutorials(true)
        
    // Clear existing items before adding new ones
    this.knowledgeHubAdminTables.forEach((table: MenuCategoryAdminTables) => {
      table.items = [];
    });

    req.pipe(take(1)).subscribe(res => {
      // Add every tutorial from the response to their respective table based on their category
      res.forEach((tutorial: Tutorial) => {
        this.knowledgeHubAdminTables.forEach((table: MenuCategoryAdminTables) => {
          if (tutorial.category_identifier === CategoryKeyMapping[table.title]) {
            table.items = [...table.items, tutorial];
          }
        });
      });
      this.isLoading = false;
      this.cdr.detectChanges();
    },
    (err) => {
      this.isLoading = false;
      this.helperService.showErrorMessage(err);
    });
  }
}
