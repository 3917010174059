<div class="btr-modal-container">
    <span mat-dialog-title>{{modalTitle}}</span>
    <mat-dialog-content>
      <div class="flex-row flex-wrap justify-between items-center" [formGroup]="itemForm">
        <ng-container *ngIf="mode === knowledgeHubTutorialModalModes.Add || mode === knowledgeHubTutorialModalModes.Rename">
          <mat-form-field appearance="outline" class="btr-form-field">
            <mat-label>Video title</mat-label>
            <input matInput formControlName="video_title" [maxLength]="INPUT_LONG_TITLE_LENGTH" required>
            <mat-hint align="end">{{itemForm?.controls['video_title'].value?.length || 0}} / {{INPUT_LONG_TITLE_LENGTH}}</mat-hint>
            <mat-error *ngIf="itemForm?.controls['video_title'].errors?.required || itemForm?.controls['video_title'].errors?.whitespace">Field cannot be empty</mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="mode === knowledgeHubTutorialModalModes.Edit">
          <div class="flex-100 subtitle-1 margin-bottom-10 word-break">{{videoTitle}}</div>
        </ng-container>
        <ng-container *ngIf="mode !== knowledgeHubTutorialModalModes.Rename">
          <div class="video-link-title">For the moment, we only support Vimeo links</div>
          <mat-form-field appearance="outline" class="btr-form-field margin-top-5">
            <mat-label>Video link</mat-label>
            <input matInput formControlName="video_link" required>
            <mat-error *ngIf="!itemForm?.get('video_link')?.value && itemForm?.controls['video_link'].errors?.required || itemForm?.controls['video_link'].errors?.whitespace">Field cannot be empty</mat-error>
            <mat-error *ngIf="itemForm?.get('video_link')?.value && itemForm?.controls['video_link'].errors?.vimeoVideo">Incorrect Vimeo link</mat-error> 
          </mat-form-field>
        </ng-container>
      </div>
      <ng-container *ngIf="mode === knowledgeHubTutorialModalModes.Add">
        <div class="vimeo-video-container">
          <app-vimeo-video [videoUrl]="videoLink"></app-vimeo-video>
        </div>
      </ng-container>
    </mat-dialog-content>

    <div class="center-loader" *ngIf="isLoading">
      <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>

    <mat-dialog-actions>
      <app-styleguide-simple-button
        type="dark-link"
        label="Cancel"
        (onClick)="close()">
      </app-styleguide-simple-button>
      <app-styleguide-simple-button
        type="green-link"
        [label]="confirmButtonLabel"
        (onClick)="save()">
      </app-styleguide-simple-button>
    </mat-dialog-actions>
  </div>
