<div class="item-data">
  <app-icon-two-images
      bigImg="comment2"
      smallImg="close2"
      [isSuccess]="false">
  </app-icon-two-images>
  <div class="item-details" (click)="goToOriginalWorkflow(notification)">
      <div class="item-details-title">
        Your <strong>execution</strong> task on <strong>{{notification.data?.link?.title}}</strong> was removed because <strong>{{notification.data?.action_user | displayName}}</strong> started the task and the "Only one user needed to complete task" checkmark was turned <strong>ON</strong>.
      </div>
      <ng-container *ngIf="notification.data?.comment">
          <div class="item-details-subtitle">With comment:</div>
          <div class="item-details-comment"
               matTooltip="{{notification.data?.comment}}"
               matTooltipClass="btr-basic"
               appTooltipSize
               matTooltipPosition="right"
               appTooltipWhenEllipsis
          >"{{notification.data?.comment}}"</div>
      </ng-container>
      <div class="item-details-time" *ngIf="company$ | async as company">
          {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
      </div>
  </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>
